import { EPaymentMethod } from '@appcharge/shared-ui/lib/components/Helper/enums';
import { useEffect } from 'react';
// @ts-ignore
import crumbs from 'crumbsjs';
import { useLocation } from 'react-router-dom';

export const makeOrder = async (paymentData: any, token: string) => {
    const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/store/orders/independent-checkout`,
        {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(paymentData)
        }
    );
    if (!response.ok) {
        throw new Error('Failed to fetch');
    }
    return response.json();
};

const Redirect = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const sessionToken = queryParams.get('s');
    const bgImage = decodeURIComponent(queryParams.get('b')!);
    const price = queryParams.get('p');
    const paymentMethod = queryParams.get('pm');

    crumbs.ls.set('bgImage', bgImage);
    crumbs.ls.set('sessionToken', sessionToken);

    useEffect(() => {
        makeOrder(
            {
                clientGaId:
                    localStorage.getItem('ga_client_id') || '2134654321',
                currency: 'usd',
                paymentProvider: 'stripe',
                paymentMethod: paymentMethod as EPaymentMethod,
                utmSource: 'none',
                sequence: 1,
                price: parseFloat(price || crumbs.ls.get('price'))
            },
            sessionToken || crumbs.ls.get('sessionToken')
        ).then((res) => {
            window.location.replace(res.paymentUrl);
        });
    }, []);

    return (
        <div
            style={{
                width: '100vw',
                height: '100vh',
                background: `url("${
                    bgImage || crumbs.ls.get('bgImage')
                }") no-repeat center center fixed`,
                backgroundSize: 'cover',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                color: 'white',
                fontFamily: 'Roboto',
                gap: '1em'
            }}
        >
            <span className="loader"></span>
            <span>Proceeding to payment...</span>
        </div>
    );
};

export default Redirect;
