import { useEffect, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { getOrder } from '@appcharge/shared-player-bl';
// @ts-ignore
import crumbs from 'crumbsjs';

const SAMPLING_INTERVAL = 2000;

const clearCookies = () => {
    crumbs.ls.delete('price');
    crumbs.ls.delete('token');
    crumbs.ls.delete('sessionMetadata');
    crumbs.ls.delete('price');
};

const Checkout = () => {
    const orderId = window.location.href.split('/').pop();
    const [orderState, setOrderState] = useState('');
    const interval = useRef<any>(null);

    useEffect(() => {
        const sampleOrder = async () => {
            return getOrder(
                process.env.REACT_APP_API_BASE_URL as string,
                crumbs.ls.get('sessionToken'),
                orderId as string,
                () => {
                    clearCookies();
                    setOrderState('success');
                    clearInterval(interval.current);
                },
                () => {
                    clearCookies();
                    setOrderState('failed');
                    clearInterval(interval.current);
                },
                (error: any) => {
                    clearCookies();
                    clearInterval(interval.current);
                    console.error('An error occurred:', error);
                    setOrderState('failed');
                },
            );
        };

        interval.current = setInterval(() => sampleOrder(), SAMPLING_INTERVAL);
    }, [orderId]);

    return (
        <ErrorBoundary fallback={<div>Something went wrong</div>}>
            <div
                style={{
                    width: '100vw',
                    height: '100vh',
                    background: `url("${crumbs.ls.get('bgImage')}") no-repeat center center fixed`,
                    backgroundSize: 'cover',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    color: 'white',
                    fontFamily: 'Roboto',
                    gap: '1em'
                }}
            >
                {orderState === 'success' ? (
                    <>
                        <span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="104"
                                height="104"
                                viewBox="0 0 24 24"
                                fill="white"
                            >
                                <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
                            </svg>
                        </span>
                        <span style={{ fontSize: 32 }}>
                            Purchase completed successfully!
                        </span>
                        <span>
                            you can close the tab and go back to the game
                        </span>
                    </>
                ) : orderState === 'failed' ? (
                    <>
                        <span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="104"
                                height="104"
                                viewBox="0 0 24 24"
                                fill="white"
                            >
                                <path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" />
                            </svg>
                        </span>
                        <span style={{ fontSize: 32 }}>
                            Something went wrong with your purchase
                        </span>
                        <span>Please contact support</span>
                    </>
                ) : (
                    <>
                        <span className="loader"></span>
                        <span>Confirming your order...</span>
                    </>
                )}
            </div>
        </ErrorBoundary>
    );
};

export default Checkout;
