import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter }                    from 'react-router-dom';
import ReactDOM                             from 'react-dom/client';
import App                                  from './App';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root')!);
const queryClient = new QueryClient();

root.render(
    <BrowserRouter>
        <QueryClientProvider client={queryClient}>
            <App />
        </QueryClientProvider>
    </BrowserRouter>
);
