import { Routes, Route } from 'react-router-dom';
import Redirect from './Redirect/Redirect';
import Payment from './Payment/Payment';
import Checkout from './Checkout/Checkout';

import MessageView from './MessageView/MessageView';

function App() {
    return (
        <div className="App">
            <Routes>
                <Route path="/" element={<Payment />} />
                <Route path="/redirect/*" element={<Redirect />} />
                <Route path="/checkout/*" element={<Checkout />} />
                <Route
                    path="/session-ended/*"
                    element={
                        <MessageView
                            title="Session has ended"
                            subtitle="You can now close this tab"
                        />
                    }
                />
            </Routes>
        </div>
    );
}

export default App;
