import React from 'react';
// @ts-ignore
import crumbs from 'crumbsjs';

interface MessageViewProps {
    title: string;
    subtitle: string;
}

const MessageView: React.FC<MessageViewProps> = ({ title, subtitle }) => {
    return (
        <div
            style={{
                width: '100vw',
                height: '100vh',
                background: `url("${crumbs.ls.get('bgImage')}") no-repeat center center fixed`,
                backgroundSize: 'cover',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                color: 'white',
                fontFamily: 'Roboto',
                gap: '1rem'
            }}
        >
            <span style={{ fontWeight: 'bold', fontSize: '2rem' }}>
                {title}
            </span>
            <span>{subtitle}</span>
        </div>
    );
}

export default MessageView;
