import { PaymentModal } from '@appcharge/shared-ui';
import {
    getPostLogin,
    getBoot,
    getPaypalToken
} from '@appcharge/shared-player-bl';
import {
    EFontWeights,
    EGradientDirection
} from '@appcharge/shared-ui/lib/components/Helper/enums';
import PaypalButton from '../PaypalButton/PaypalButton';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
// @ts-ignore
import crumbs from 'crumbsjs';

const Payment = () => {
    const [token, setToken] = useState('');
    const [paypalToken, setPaypalToken] = useState('');
    const [isLoadingBoot, setIsLoadingBoot] = useState(true);
    const [paymentMethods, setPaymentMethods] = useState<Array<any>>([]);
    const [paypalLoading, setPaypalLoading] = useState(false);
    const [paypalSuccess, setPaypalSuccess] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const localAddress = queryParams.get('local_address') || null;
    const bootLocation = queryParams.get('bootLocation') || '';
    const playerId = queryParams.get('playerId') || '';
    const preLoad = queryParams.get('preLoad') || null;
    const mixpanelSessionId = queryParams.get('mixpanelSessionId') || '';
    const price = queryParams.get('price') || '';
    crumbs.ls.set('price', price);
    const sessionMetadata = JSON.parse(
        decodeURIComponent(queryParams.get('sessionMetadata') || '{}')
    );

    useEffect(() => {
        const _getBoot = async () => {
            return getBoot(
                process.env.REACT_APP_API_BASE_URL as string,
                bootLocation,
                (bootResponse) => {
                    setIsLoadingBoot(false);
                    setToken(bootResponse.integration?.checkoutToken || '');
                    crumbs.ls.set('token', token);
                    crumbs.ls.set(
                        'bgImage',
                        bootResponse.storeTheme.general.backgroundImageDesktop
                    );
                    crumbs.ls.set(
                        'sessionMetadata',
                        JSON.stringify(sessionMetadata)
                    );
                    return bootResponse.integration?.checkoutToken || '';
                },
                (response) => {
                    console.error(
                        'Request failed with status:',
                        response.status
                    );
                },
                (error) => {
                    console.error('An error occurred:', error);
                }
            );
        };

        const getLogin = async (bootToken: string) => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_BASE_URL}/store/auth/checkout`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'x-checkout-token': bootToken
                        },
                        body: JSON.stringify({
                            playerId,
                            mixpanelSessionId,
                            sessionMetadata,
                            ...(localAddress &&
                                localAddress !== null && { localAddress })
                        })
                    }
                );

                if (response.ok) {
                    const data = await response.json();
                    crumbs.ls.set('sessionToken', data.sessionToken);
                    return data.sessionToken;
                } else {
                    console.error(
                        'Request failed with status:',
                        response.status
                    );
                }
            } catch (error) {
                console.error('An error occurred:', error);
            }
        };

        const _getPaypalToken = async (sessionToken: string) => {
            return getPaypalToken(
                process.env.REACT_APP_API_BASE_URL as string,
                sessionToken,
                (paypalToken) => {
                    setPaypalToken(paypalToken);
                },
                (response) => {
                    console.error(
                        'Request failed with status:',
                        response.status
                    );
                },
                (error) => {
                    console.error('An error occurred:', error);
                }
            );
        };

        const _getPostLogin = async (sessionToken: string) => {
            return getPostLogin(
                process.env.REACT_APP_API_BASE_URL as string,
                sessionToken,
                (data) => {
                    crumbs.ls.set('currencyCode', data.playerCurrencyCode);
                    setPaymentMethods(data.paymentMethods);
                    setIsLoadingBoot(false);
                },
                (response) => {
                    console.error(
                        'Request failed with status:',
                        response.status
                    );
                },
                (error) => {
                    console.error('An error occurred:', error);
                }
            );
        };

        const fetchData = async () => {
            try {
                const bootToken = (await _getBoot()) as string;
                if (!preLoad) {
                    const sessionToken = await getLogin(bootToken);
                    await _getPaypalToken(sessionToken);
                    await _getPostLogin(sessionToken);
                }
            } catch (error) {
                throw new Error();
            }
        };

        if (Array.from(queryParams).length === 0) {
            navigate('/session-ended');
        } else {
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ErrorBoundary
            fallback={
                <div
                    style={{
                        width: '100vw',
                        height: '100vh',
                        color: 'white',
                        display: 'flex',
                        fontSize: '24px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontFamily: 'Roboto',
                        flexDirection: 'column',
                        gap: '8px'
                    }}
                >
                    <svg
                        width="64"
                        height="64"
                        xmlns="http://www.w3.org/2000/svg"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        viewBox="0 0 24 24"
                        style={{ marginBottom: '8px' }}
                    >
                        <path
                            fill="white"
                            d="M12 0c6.623 0 12 5.377 12 12s-5.377 12-12 12-12-5.377-12-12 5.377-12 12-12zm0 1c6.071 0 11 4.929 11 11s-4.929 11-11 11-11-4.929-11-11 4.929-11 11-11zm-.019 14c1.842.005 3.613.791 5.117 2.224l-.663.748c-1.323-1.27-2.866-1.968-4.456-1.972h-.013c-1.568 0-3.092.677-4.4 1.914l-.664-.748c1.491-1.4 3.243-2.166 5.064-2.166h.015zm-3.494-6.5c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1zm7.013 0c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1z"
                        />
                    </svg>
                    <span style={{ fontWeight: 'bold' }}>
                        Something went wrong
                    </span>
                    <span style={{ fontSize: '18px' }}>
                        Try to close and reopen this popup
                    </span>
                </div>
            }
        >
            {isLoadingBoot || !paypalToken ? (
                <div
                    style={{
                        width: '100vw',
                        height: '100vh',
                        color: 'white',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontFamily: 'Roboto'
                    }}
                >
                    <span className="loader"></span>
                </div>
            ) : (
                <>
                    <div
                        style={{
                            display: paypalLoading ? 'flex' : 'none',
                            width: '100vw',
                            height: '100vh',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontFamily: 'Roboto',
                            color: 'white',
                            fontSize: '24px',
                            flexDirection: 'column',
                            gap: '16px'
                        }}
                    >
                        <span className="loader"></span>
                        Contacting Paypal...
                    </div>
                    <div
                        style={{
                            display: paypalSuccess ? 'flex' : 'none',
                            width: '100vw',
                            height: '100vh',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontFamily: 'Roboto',
                            color: 'white',
                            fontSize: '24px',
                            flexDirection: 'column',
                            gap: '16px'
                        }}
                    >
                        <span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="68"
                                height="68"
                                viewBox="0 0 24 24"
                                fill="white"
                            >
                                <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
                            </svg>
                        </span>
                        Purchase completed successfully!
                    </div>
                    <div
                        style={{
                            display:
                                paypalLoading || paypalSuccess
                                    ? 'none'
                                    : 'block'
                        }}
                    >
                        <PaymentModal
                            sessionToken={crumbs.ls.get('sessionToken')}
                            bgImage={crumbs.ls.get('bgImage')}
                            price={price}
                            redirectUrl={window.location.origin}
                            showCloseButton={false}
                            showOverlay={false}
                            isStandAlone={true}
                            headerText={''}
                            headerColor={{
                                colorOne: 'white'
                            }}
                            headerSize={34}
                            text={''}
                            textColor={'white'}
                            textSize={24}
                            textWeight={'normal' as EFontWeights}
                            modalColor={{
                                colorOne: 'transparent',
                                colorTwo: 'transparent',
                                gradientDirection:
                                    'to right' as EGradientDirection
                            }}
                            modalBorderColor={{
                                colorOne: 'transparent',
                                colorTwo: 'transparent',
                                gradientDirection:
                                    'to right' as EGradientDirection
                            }}
                            closeModal={function (): void {
                                throw new Error('Function not implemented.');
                            }}
                            paymentClicked={() => {}}
                            buttonColor={{
                                colorOne: 'black',
                                colorTwo: 'white',
                                gradientDirection:
                                    'to right' as EGradientDirection
                            }}
                            preview={false}
                            fontFamily={'Roboto'}
                            buttonTextColor={''}
                            paymentMethods={paymentMethods}
                            paypalButton={
                                <PaypalButton
                                    clientToken={paypalToken}
                                    selectedOffer={'test'}
                                    utmSource={'none'}
                                    key="paypal"
                                    onPaypalApprove={() => {
                                        setPaypalLoading(true);
                                    }}
                                    onSuccess={() => {
                                        setPaypalLoading(false);
                                        setPaypalSuccess(true);
                                    }}
                                />
                            }
                            visible={true}
                        />
                    </div>
                </>
            )}
        </ErrorBoundary>
    );
};

export default Payment;
