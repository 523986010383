import { PaypalButtonProps } from './PaypalButton.types';
import {
    PayPalScriptProvider,
    BraintreePayPalButtons
} from '@paypal/react-paypal-js';
import Decimal from 'decimal.js';
import { makeOrder } from '../Redirect/Redirect';
// @ts-ignore
import crumbs from 'crumbsjs';

export const checkoutOrder = async (payload: any, token: string) => {
    const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/store/orders/checkout`,
        {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(payload)
        }
    );
    if (!response.ok) {
        throw new Error('Failed to fetch');
    }
    return true;
};

const PaypalButton = ({
    clientToken,
    selectedOffer,
    utmSource,
    onError,
    onPaypalApprove,
    onSuccess
}: PaypalButtonProps) => {
    const currencyCode = crumbs.ls.get('currencyCode');

    return (
        <PayPalScriptProvider
            options={{
                'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID || 'test',
                'data-client-token': clientToken,
                'disable-funding':
                    'card,venmo,credit,paylater,bancontact,blik,eps,giropay,ideal,mybank,p24,sepa,sofort,mercadopago',
                currency: currencyCode
            }}
        >
            <BraintreePayPalButtons
                style={{ height: 50 }}
                className="button"
                createOrder={async (data, actions) => {
                    const paymentData = {
                        clientGaId:
                            localStorage.getItem('ga_client_id') ||
                            '2134654321',
                        paymentProvider: 'paypal',
                        paymentMethod: 'paypal',
                        utmSource: 'none',
                        sequence: 1,
                        price: parseFloat(crumbs.ls.get('price')!)
                    };
                    const getOrderResponse = async () => {
                        try {
                            return await makeOrder(
                                paymentData,
                                crumbs.ls.get('sessionToken') || ''
                            );
                        } catch (error) {
                            console.error(error);
                            onError?.();
                        }
                    };
                    const orderResponse = await getOrderResponse();
                    if (!orderResponse) throw new Error('create order failed');
                    crumbs.ls.set('orderId', orderResponse.orderId);

                    return actions.braintree.createPayment({
                        flow: 'checkout',
                        amount: new Decimal(orderResponse.price.priceTotal)
                            .div(100)
                            .toString(),
                        currency: currencyCode,
                        requestBillingAgreement: false,
                        intent: 'capture',
                        enableShippingAddress: true,
                        shippingAddressEditable: false,
                        // billingAgreementDescription: 'Description of the billng agreement to display to the customer',
                        lineItems: [
                            {
                                quantity: '1',
                                unitAmount: new Decimal(
                                    orderResponse.price.priceNet
                                )
                                    .div(100)
                                    .toString(),
                                name: 'Bundle',
                                kind: 'debit',
                                unitTaxAmount: new Decimal(
                                    orderResponse.price.tax
                                )
                                    .div(100)
                                    .toString(),
                                url: undefined,
                                description: 'Bundle',
                                productCode: 'Bundle'
                            }
                        ]
                    });
                }}
                onApprove={(data, actions) => {
                    return actions.braintree
                        .tokenizePayment(data)
                        .then(async (payload) => {
                            onPaypalApprove?.();
                            const checkoutData = {
                                orderId: crumbs.ls.get('orderId')!,
                                paymentProvider: 'paypal',
                                params: {
                                    paymentNonce: payload.nonce
                                }
                            };
                            try {
                                await checkoutOrder(
                                    checkoutData,
                                    crumbs.ls.get('sessionToken')!
                                );
                                onSuccess?.();
                            } catch (error) {
                                console.error(error);
                                throw new Error();
                            }
                        });
                }}
            />
        </PayPalScriptProvider>
    );
};

export default PaypalButton;
